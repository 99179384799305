@import 'assets/styles/partials/base/variables';

.container-rebalancing {
  position: relative;

  .planification-accordion {
    td.permutation {
      color: $table-blue;
    }
  }

  .accordion-container {
    height: $height-rebalancing-accordion-container;

    .card-header-title {
      font-weight: 500;
    }
  }

  .secondary-substation-container {
    height: $height-impact-table-container;
    background-color: $white;
    width: 100%;
  }

  .field-label {
    flex-basis: fit-content;
    flex-grow: initial;
  }

  .table-container {
    box-sizing: border-box;
    width: 100%;

    .list-cell {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .small-cell {
      width: 7%;
    }

    td {
      height: 3rem;
      line-height: 3rem;
      font-size: large;
    }

    .rebalancing-checkbox-off,
    .rebalancing-checkbox-on {
      display: inline-block;
      margin-right: .4rem;
    }

    .rebalancing-cell {
      overflow: hidden;
    }

    .rebalancing-value {
      display: inline-block;
    }

    .columns:last-child {
      margin-bottom: 0;
    }

    .list-header:last-child {
      flex-grow: 1;
    }

    label {
      margin-right: .5rem;
    }
  }

  .rebalancing-table {
    .list-header-container {
      flex-direction: row;
      height: 4.5rem;
    }
  }
}

.rebalancing-plan-table {
  .list-header-container {
    align-items: center;
    margin-bottom: 1rem;
  }

  .list-header {
    border: 0;
    text-transform: uppercase;
  }

  .feeder-row {
    text-transform: uppercase;
    text-align: center;
    font-size: large;
    background-color: $grey96;
    width: 100%;
    height: 3rem;
    line-height: 3rem;
    display: flex;
    flex-direction: column;

    td {
      padding: 0;
    }
  }
}

.carousel-th {
  border-right: .0625rem solid $dashboard-border-color;
  border-bottom: .0625rem solid $dashboard-border-color;
}

.carousel-th-width {
  width: 2rem;
}
