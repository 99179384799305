/* Variables */
$scrollbar-width: .5rem;
$header-height: 3rem;
$tab-title-height: 3rem;
$primary-tab-top-margin: 1rem;
$primary-tab-left-margin: 1rem;
$primary-tabs-padding: .75rem;
$secondary-tab-margin: .5rem;
$carousel-button-width: 2.5rem;
$secondary-tab-height: $tab-title-height + $scrollbar-width;
$primary-tab-height: $tab-title-height + $primary-tab-top-margin;
$date-filter-container-height: 3rem;
$height-impact-secondary-substation-header: 6rem;
$height-impact-table-container: calc(100vh - #{$header-height} - #{$primary-tab-height});
$height-impact-table: calc(100vh - #{$height-impact-table-container} - #{$height-impact-secondary-substation-header});
$height-analysis-graph-container: calc(#{$height-impact-table-container} - #{$secondary-tab-height});
$width-mapping-sidebar: 21.75rem;
$banner-height: 4rem;
$height-rebalancing-accordion-container: calc($height-impact-table-container - $banner-height);

/* Colors */
$turquoise: #6db6c2;
$turquoise-darker: #1b9cb2;
$turquoise-light: #d0e6ea;
$turquoise-lighter: #e2f0f3;
$turquoise-text: #478a91;
$turquoise-transparent: rgb(226 240 243 / 30%);
$turquoise-dark: #1c717f;
$blue: #0985e4;
$table-blue: #296fa8;
$red: #e83f39;
$orange-warning: #f4a460;
$green: #00b380;
$white: #fff;
$black: #000;
$blue-grey: #4b4f58;
$light-blue-grey: #6c757d;
$grey20: #333;
$grey32: #515151;
$grey50: #808080;
$grey75: #bfbfbf;
$grey81: #cecece;
$grey85: #d9d9d9;
$grey92: #e7e8ef;
$grey95: #f3f3f3;
$grey96: #f6f6f6;
$grey98: #fafafa;
$graph-light-grey-1: #e8eaee;
$graph-light-grey-2: #08080a;
$shadow-alpha-5: rgb(0 0 0 / 5%);
$shadow-alpha-20: rgb(0 0 0 / 20%);
$shadow-alpha-25: rgb(0 0 0 / 25%);
$shadow-alpha-50: rgb(0 0 0 / 50%);
$transparent: rgb(0 0 0 / 0%);
$yellow: #ffe92d;
$bright-yellow: #ffd12d;
$transparent-white: rgb(255 255 255 / 80%);
$graph-blue: #1f77b4;
$graph-orange: #ff7f0e;
$graph-green: #2ca02c;
$graph-purple: #7542e5;
$permuted-counter: #ff851b;
$unpermuted-counter: #6fb1fc;

/* Colors for Color Scale */
$light-green-1: #090;
$light-green-2: #4faf0a;
$light-green-3: #8fc112;
$yellow-green: #d7ce18;
$warm-yellow: #fec013;
$orange: #f39605;
$warm-orange: #da6100;
$dark-red: #b30000;
$dark-red-2: #800000;
$dark-red-3: #300000;
$light-blue-1: rgb(199 221 255);
$light-blue-2: rgb(181 209 252);
$light-blue-3: rgb(138 181 245);
$dark-blue-1: rgb(20 86 185);
$dark-blue-2: rgb(6 52 121);
$dark-blue-3: rgb(5 25 54);

/* Color for Heat Maps */
$warm-orange-light: #fff5f0;

$colors-green-red-color-scale: ($light-green-1, $light-green-2, $light-green-3, $yellow-green,
$warm-yellow, $orange, $warm-orange, $dark-red);
$colors-green-red-color-scale-5: ($light-green-1, $light-green-3, $warm-yellow, $orange, $dark-red);
$colors-green-red-color-scale-4: ($light-green-1, $warm-yellow, $orange, $dark-red);
$colors-green-red-color-scale-3: ($light-green-1, $orange, $dark-red);
$colors-red-color-scale-3: ($dark-red, $dark-red-2, $dark-red-3);
$colors-red-green-color-scale-3: ($dark-red, $orange, $light-green-1);
$white-blue-color-scale-5: ($light-blue-1, $light-blue-2, $light-blue-3, $dark-blue-1, $dark-blue-2);
$blue-color-scale-3: ($light-blue-1, $light-blue-3, $dark-blue-2);

/* Colors to export for graphs */
$graph-js-colors: (
  blueDark: #3077af,
  greenSaturated: #00ca28,
  orangeSaturated: #fa6400,
  brown: #a97a51,
  yellowDesaturated: #cbc062,
  purple: #7542e5
);

/* Fonts */
$quicksand: 'Quicksand', sans-serif;
$aktiv-grotesk: 'AktivGrotesk', 'Helvetica Neue', helvetica, arial, sans-serif;

/* Set Bulma variables */

/* Colors */
$disabled-text: $grey81;
$body-background-color: $white;
$login-title-color: $white;
$border: $grey85;
$link: $blue;
$primary-light: $turquoise-light;
$primary: $turquoise;
$secondary: $red;
$text: $blue-grey;
$input-background-color: $grey96;
$input-placeholder-color: $light-blue-grey;
$success: $green;

/* Fonts */
$family-primary: $quicksand;
$family-secondary: $aktiv-grotesk;
$body-family: $family-secondary;
$body-font-size: 1rem;
$title-family: $family-primary;
$subtitle-family: $body-family;

/* Other variables */

/* Colors */
$dashboard-background: $grey95;
$dropdown-border-color: $grey92;
$dashboard-border-color: $grey85;
$nav-link: $grey50;
$equipment-list-selected: $turquoise-lighter;
$equipment-list-selected-impact: $grey95;
$modal-overlay: rgba($body-background-color, 50%);
$modal-overlay-dark: rgba($black, 75%);
$info-icon-default-color: #4b4f58;
$info-icon-blink-color: #5a6eaa;

/* Shadows */
$header-shadow: 0 .125rem .5rem 0 $shadow-alpha-25;
$map-shadow: .0625rem .0625rem .0625rem 0 rgb($black, 30%);
$map-popup-shadow: 0 .125rem .3125rem 0 rgb(0 0 0 / 50%);
$map-popup-tip-shadow: 0 -.125rem .3125rem 0 rgb(0 0 0 / 50%);
$context-contenu-shadow: 0 0 1rem 0 $grey81;
$map-colorbar-shadow: 0 0 .9rem $shadow-alpha-20;

/* Borders */
$border-modal: .125rem solid $graph-light-grey-1;

/* Animation */
$info-fade: 300ms;

/* stylelint-disable value-keyword-case */
$loader-spin: spinAround 1s infinite linear;
/* stylelint-enable value-keyword-case */
