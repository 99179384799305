@import 'assets/styles/partials/base/variables';

.container-page {
  position: relative;
}

.container-impact {
  .info-container {
    overflow: hidden;
    white-space: nowrap;

    .legend,
    .color-square,
    .color-line {
      display: inline-block;
    }
  }

  .no-padding-left {
    padding-left: 0;
  }

  .is-height-2 {
    height: 2rem;
  }

  .field-label {
    text-align: left;
  }

  .modal-background {
    background-color: $shadow-alpha-50;
  }

  .secondary-substation-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: $height-impact-table-container;
    background-color: $white;

    &.fullscreen {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      z-index: 20;
      position: fixed;
    }
  }

  .secondary-substation-header {
    height: $height-impact-secondary-substation-header;
    align-items: center;
    padding: 1rem 1rem 0;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      color: $turquoise;
      width: 50%;
      font-size: 1.75rem;
    }

    .substation-icon {
      margin-right: .5rem;
    }

    .expand {
      cursor: pointer;
      color: $text;
    }
  }

  .table-container {
    flex-grow: 1;
  }
}
